<template>
  <div class="ml-table-container">
    <el-table
      ref="MlTable"
      v-loading="loading"
      v-bind="$attrs"
      :data="table"
      :show-header="showHeader"
      :height="height"
      :border="border"
      :expand-row-keys="expandRowKeys"
      row-key="id"
      style="width: 100%"
      highlight-current-row
      v-on="$listeners"
      @sort-change='changeTableSort'
    >
      <slot name="columns" />
    </el-table>
    <div class="ml-table-container__footer">
      <div>
        <slot name="footer" />
      </div>
      <el-pagination
        :current-page.sync="pagination.current_page"
        :page-sizes="[5,10,15,20,100, 200, 300, 400]"
        :page-size.sync="pagination.page_size"
        layout="total, sizes, prev, pager, next"
        :total="pagination.total"
        @current-change="eventPageChange"
        @size-change="eventSizeChange"
      />
    </div>
  </div>
</template>
<script>
import api_common from '@/api/common'

export default {
  name: 'MlTable',
  props: {
    api: {
      type: Function,
      required: true
    },
    search: {
      type: Object,
      default() {
        return {}
      }
    },
    total: {
      type: Number,
      default() {
        return 0
      }
    },
    height: {
      type: [String, Boolean],
      default() {
        return 'calc(100% - 32px - 12px)'
      }
    },
    expandRowKeys: {
      type: Array,
      default() {
        return []
      }
    },
    handRequest: Boolean, // created自动请求开关
    border: Boolean,
    showHeader: {
      type: Boolean,
      default() {
        return true
      }
    }
  },
  data() {
    return {
      loading: false,
      orderBy: null,
      orderByRaw: null,
      table: [],
      pagination: {
        current_page: 1,
        page_size: 20,
        total: 0
      }
    }
  },
  watch: {
    'pagination.total'(_) {
      this.$emit('update:total', _)
    }
  },
  created() {
    if (!this.handRequest) {
      this.handleSearch()
    }
  },
  methods: {
    doLayout:function (){
      this.$refs.MlTable.doLayout()
    },
    getSelection: function() {
      return this.$refs.MlTable.selection
    },
    changeTableSort: function({ prop, order }) {
      let orderBy = {}
      let orderByRaw = null
      if (order) {
        orderBy[prop] = order === 'descending' ? 'desc' : 'asc'
        orderByRaw = `${prop} ${orderBy[prop]}`
      } else {
        orderBy = null
      }
      this.orderBy = orderBy
      this.orderByRaw = orderByRaw
      this.handleSearch()
    },
    eventSizeChange: function() {
      this.handleSearch()
    },
    eventPageChange: function(current_page) {
      this.pagination.current_page = current_page
      this.getTable()
    },
    handleSearch: function() {
      this.pagination.current_page = 1
      this.getTable()
    },
    getTable: async function() {
      if (this.loading) {
        return
      }
      this.loading = true
      const response = await api_common.getPageCommon(this.api, this.getParam())
      const { list, pages } = response
      setTimeout(() => {
        this.loading = false
      }, 80)
      this.table = list || []
      this.pagination.total = pages.total
      this.$emit('response', response)
    },
    getParam: function() {
      let params = { ...{}, ...this.search }
      params = { ...params, ...{ orderBy: this.orderBy, orderByRaw: this.orderByRaw } }
      for (const paramsKey in params) {
        const value = params[paramsKey]
        if (value === '' || value === null) {
          delete params[paramsKey]
        }
      }
      const result = { ...params, ...this.pagination }
      this.$emit('update:search', result)
      return result
    }
  }
}
</script>
<style lang="scss">
.ml-table-container {
  height: 100%;

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
  }
}
</style>
