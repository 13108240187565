<template>
  <div class="wrapper">
    <el-form class="search-form" inline size="mini">
      <el-form-item>
        <el-radio-group v-model="type">
          <el-radio-button label="PRODUCT">产品</el-radio-button>
          <el-radio-button label="SELECTION">排期</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="type==='PRODUCT'">
        <product-leader-select :leader-type="libraryType==='product'?'product':'product-dress'"
                               :leader-id.sync="search.leader_id"
                               @selected="handleSearch" placeholder="负责人搜索"/>
      </el-form-item >
      <el-form-item v-else>
        <product-leader-select :leader-type="libraryType==='product'?'selection':'selection-dress'"
                               :leader-id.sync="search.lb_s_p_leader_id"
                               @selected="handleSearch" placeholder="负责人搜索"/>
      </el-form-item>
      <el-form-item>
        <select-brand v-if="libraryType==='product'" :value.sync="search.brand_id" placeholder="品牌搜索"
                      @change="handleSearch"/>
        <el-input v-else v-model='search.brand_name' placeholder='请输入品牌' clearable
                  @change='handleSearch'></el-input>

      </el-form-item>
      <template v-if="type==='SELECTION'">
        <el-form-item>
          <artist-search :artist-id.sync="search.artist_id" @handleSelect='handleSearch' />
        </el-form-item>
        <el-form-item>
          <el-select v-model="search.lb_schedules_type" placeholder="排期类型搜索" @change="handleSearch">
            <el-option label="选品日期" value="1" />
            <el-option label="直播日期" value="2" />
          </el-select>
        </el-form-item>
      </template>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleSearch">查询</el-button>
        <el-button icon="el-icon-refresh-left" size="mini" @click="handleResetSearch">重置</el-button>
      </el-form-item>
    </el-form>
    <ml-table v-if="type==='PRODUCT'&&libraryType" ref="TABLE_PRODUCT"
              :api="libraryType==='product'?$api.getProductList:$api.getProductDressList" :search="search"
              style="height: calc(76vh - 53px - 20px)">
      <template slot="columns">
        <el-table-column type="selection" width="55" />
        <el-table-column type="index" label="序号" width="55" />
        <el-table-column label="产品名称" prop="title" width="260" show-overflow-tooltip />
        <el-table-column label="品牌" prop="brand_name" show-overflow-tooltip />
        <el-table-column label="类目" prop="category_id" min-width="100" align="center">
          <template slot-scope="{row}">
            <span>{{ row.category || '' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="规格" prop="spec_name" align="center" min-width="200"
                         show-overflow-tooltip></el-table-column>
        <el-table-column label="公司" prop="company_name" align="center" min-width="120" show-overflow-tooltip>
          <template slot-scope="{row}">
            <span>{{ row.company_name || '' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="负责人" prop="leader_id" align="center" min-width="100">
          <template slot-scope="{row}">
            <span>{{ row.leader_name || '' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="created_at" align="center" min-width="140" />
        <el-table-column label="修改时间" prop="updated_at" align="center" min-width="140" />
      </template>
      <template slot="footer">
        <el-button type="primary" @click='handleHandover'>
          批量交接
        </el-button>
      </template>
    </ml-table>
    <ml-table v-else key="TABLE_SELECTION" ref="TABLE_SELECTION"
              :api="libraryType==='product'?$api.getLbScheduleProductsPage:$api.getLbScheduleDressPage"
              :search="search"
              style="height: calc(76vh - 53px - 20px)">
      <template slot="columns">
        <el-table-column type="selection" width="55" />
        <el-table-column type="index" label="序号" width="55" />
        <el-table-column label="产品名称" prop="product_title" width="260" show-overflow-tooltip>
          <!--          <template slot-scope="{row}">-->
          <!--            <span>{{ libraryType === 'product' ? row.product_title : row.title }}</span>-->
          <!--          </template>-->
        </el-table-column>
        <el-table-column label="品牌" prop="brand_name" show-overflow-tooltip />
        <el-table-column label="选品负责人" prop="leader_name" show-overflow-tooltip />
        <el-table-column label="类型" align="center" show-overflow-tooltip>
          <template v-slot="{row:{lb_schedules_type}}">
            <span v-if="lb_schedules_type===1">选品日期</span>
            <span v-else-if="lb_schedules_type===2">直播日期</span>
          </template>
        </el-table-column>
        <el-table-column label="归属排期" align="center" min-width="260" show-overflow-tooltip>
          <template v-slot="{row:{nickname,platform_name,start_at,end_at}}">
            【{{ nickname }}】{{ Array.isArray(platform_name) ? platform_name.join('、') : '' }}
            {{ start_at|parseTime }}<span v-if="end_at">~{{ end_at|parseTime }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="created_at" align="center" min-width="100" />
        <el-table-column label="修改时间" prop="updated_at" align="center" min-width="100" />
      </template>
      <template slot="footer">
        <el-button type="primary" @click='handleHandover'>
          批量交接
        </el-button>
      </template>
    </ml-table>

    <el-dialog title="交接确认" :visible.sync='handover.visible' width='500px' center
               custom-class='modify-contact-dialog'>
      <el-form ref='form' :model='handover.form' label-width='80px' size='medium'>
        <el-form-item label="勾选产品">
          {{ handover.form.ids.length }}个
        </el-form-item>
        <el-form-item label="交接人" prop='leader_id'
                      :rules="{ required: true, message: '请选择交接人', trigger: 'blur,change' }">
          <product-leader-select :leader-id.sync="handover.form.leader_id"
                                 :leader-type="'select_by_role_shang_pin'"
                                 :leader-name.sync="handover.form.leader_name"/>
        </el-form-item>
      </el-form>
      <div style="display: flex;align-items:center;width: calc(100% - 106px);padding-left: 60px;color: #999999">
        <i class="el-icon-warning" style="font-size: 24px;color: coral;margin-right: 4px;" />
        <div v-if="handover.form.leader_name">
          此操作将会把勾选产品（{{ handover.form.ids.length }}个）<br />交接给【{{ handover.form.leader_name }}】<br />
          操作后将无法撤回，是否继续？
        </div>
        <div v-else>
          请选择新的负责人/交接人
        </div>
      </div>
      <div slot='footer' class='dialog-footer'>
        <el-button @click='handover.visible = false' size='medium'>取 消</el-button>
        <ml-button text='确 定' size='medium' @click.native='handleHandoverSubmit' />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import MlTable from '@/components/MlTable/index.vue'
import ProductLeaderSelect from '@/pages/lb/components/ProductLeaderSelect.vue'
import SelectBrand from '@/pages/lb/product/component/select-brand.vue'
import ArtistSearch from '@/components/artist/ArtistSearch.vue'
import MlButton from '@/components/MlButton/index.vue'

export default {
  components: { MlButton, ArtistSearch, SelectBrand, ProductLeaderSelect, MlTable },
  data() {
    return {
      type: 'PRODUCT',
      search: {
        brand_id: null,
        leader_id: null,
        artist_id: null,
        lb_schedules_type: null
      },
      handover: {
        visible: false,
        form: {
          ids: [],
          leader_id: null,
          leader_name: null
        }
      },
      libraryType: null
    }
  },
  mounted() {
    this.libraryType = this.$route.query.type
  },
  methods: {
    handleHandover: function() {
      const component = this.$refs[`TABLE_${this.type}`]
      if (!component || (component && typeof component.getSelection !== 'function')) {
        return this.$message.error('选中操作异常，请联系管理员')
      }
      const selection = component.getSelection()
      const ids = selection.map(_ => {
        return _.id
      })
      if (!ids.length) {
        return this.$message.warning('请选择行数据')
      }
      this.handover = {
        visible: true,
        form: {
          ids,
          leader_id: null,
          leader_name: null
        }
      }
    },
    handleHandoverSubmit: async function() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: '产品交接中，请稍后',
            spinner: 'el-icon-loading',
            background: 'rgba(255, 255, 255, 0.7)'
          })
          let response
          if (this.type === 'PRODUCT') {
            response = await this.$api.productBatchHandOver(this.handover.form)
          } else {
            if (this.libraryType === 'product') {
              response = await this.$api.handoverProducts(this.handover.form)
            } else {
              response = await this.$api.handoverDress(this.handover.form)
            }
          }
          const { total } = response
          loading.close()
          if (total) {
            this.$notify.success(`交接成功:${total}个产品`)
          }
          this.handover.visible = false
          // 刷新当前页码table
          this.$refs[`TABLE_${this.type}`].getTable && this.$refs[`TABLE_${this.type}`].getTable()
        }
      })
    },
    handleSearch: function() {
      this.$refs[`TABLE_${this.type}`].handleSearch && this.$refs[`TABLE_${this.type}`].handleSearch()
    },
    handleResetSearch: function() {
      this.search = {
        brand_id: null,
        leader_id: null,
        artist_id: null,
        lb_schedules_type: null
      }
      this.$nextTick(() => {
        this.handleSearch()
      })
    }
  }
}
</script>
<style lang="scss">
.productHandover_main-ape {
  height: calc(100vh - 84px);

  .wrapper {
    background-color: #f7f8fa;
    height: calc(100% - 90px);
    padding: 18px;
  }

  .search-form {
    background: #fff;
    border-radius: 12px;
    padding: 12px 20px;
    margin-bottom: 20px;

    .el-form-item--mini.el-form-item {
      margin-bottom: 0;
    }
  }

  .ml-table-container {
    background: white;
    border-radius: 12px;
    padding: 12px 20px;
  }
}
</style>
